import React from "react";
import Heading from "../../common/heading";

function Index() {
  return (
    <div
      id="roadmap"
      className="mt-16 md:mt-20 lg:mt-24 pb-16 md:pb-20 lg:pb-24 flex flex-col items-center justify-center px-4 xl:px-0"
    >
      <div className="flex flex-col items-center justify-center w-full md:w-11/12 xl:w-10/12">
        <Heading content="Roadmap" />
        {/* roadmap section starts here */}
        <div className="w-full mt-10 md:mt-12 lg:mt-16">
          <div className="lg:flex-row flex-col-reverse flex flex-col  w-full items-center justify-between">
            <div className="lg:w-1/2 ">
              <div className="flex flex-col items-center justify-center flex-col-reverse  xl:flex-row xl:items-end w-full">
                <div
                  className="flex-shrink-0 w-350px sm:w-414px  h-248px border-4 border-yellow2 flex flex-col items-center justify-center relative "
                  style={{ background: `url(images/roadmapBg.png)` }}
                >
                  <div
                    style={{ backgroundImage: `url(images/roadmapLine.png)` }}
                    className="w-full absolute top-0 h-6"
                  />
                  <div className="text-xs sm:text-sm font-medium leading-5 text-white2 text-center px-2 sm:px-4">
                    This is where the story begins for The Shakai community. The
                    discord is created and at-least 40,000 members get prepared
                    for the release. Giveaways and teasers will be released to
                    keep the community engaged. Focus on partnerships and
                    collaborations with other upcoming projects to get us some
                    much deserved attention. Sell out the entire public sale and
                    focus on a marketing campaign which will give us global
                    recognition.
                  </div>
                  <div
                    style={{ backgroundImage: `url(images/roadmapLine.png)` }}
                    className="w-full absolute bottom-0 h-6"
                  />
                </div>
                <div className="flex justify-center items-center mb-4 xl:mb-0 xl:border-b-4 border-yellow2 text-3xl text-white2 f-f-h font-bold w-full">
                  10%
                </div>
              </div>
            </div>
            <img
              className="xl:block hidden"
              src="images/hollowBorder.png"
              alt="border"
            />
            <div className="lg:w-1/2  h-252px  flex flex-col items-end justify-center mb-8 lg:mb-0">
              <img src="images/5c.png" alt="word" />
            </div>
          </div>
          <div className="lg:flex-row flex-col-reverse flex flex-col  w-full items-center justify-between mt-8 lg:mt-0">
            <div className="lg:w-1/2 opacity-0">
              <img src="images/5c.png" alt="word" />
            </div>
            <img
              src="images/hollowBorder2.png"
              className="-mt-1 xl:block hidden"
              alt="border"
            />
            <div className="lg:w-1/2">
              <div className="flex flex-col items-center justify-center   xl:flex-row xl:items-end w-full">
                <div className="flex justify-center items-center mb-4 xl:mb-0 xl:border-b-4 border-yellow2 text-3xl text-white2 f-f-h font-bold w-full">
                  25%
                </div>
                <div
                  className="flex-shrink-0 w-350px sm:w-414px h-248px border-4 border-yellow2 flex flex-col items-center justify-center relative "
                  style={{ background: `url(images/roadmapBg.png)` }}
                >
                  <div
                    style={{ backgroundImage: `url(images/roadmapLine.png)` }}
                    className="w-full absolute top-0 h-6"
                  />
                  <div className="text-xs sm:text-sm font-medium leading-5 text-white2 text-center px-2 sm:px-4">
                    Fellow Shakais get ready to be apart of our weekly manga
                    releases. Professional designed by anime artists and
                    creators. All proceeds from our paperback editions will be
                    directly given to the owners of any featured Shakai
                    characters.
                  </div>
                  <div
                    style={{ backgroundImage: `url(images/roadmapLine.png)` }}
                    className="w-full absolute bottom-0 h-6"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:flex-row flex-col-reverse flex flex-col  w-full items-center">
            <div className="lg:w-1/2 ">
              <div className="flex flex-col items-center justify-center flex-col-reverse  xl:flex-row xl:items-end w-full">
                <div
                  className="flex-shrink-0 w-350px sm:w-414px  h-248px border-4 border-yellow2 flex flex-col items-center justify-center relative "
                  style={{ background: `url(images/roadmapBg.png)` }}
                >
                  <div
                    style={{ backgroundImage: `url(images/roadmapLine.png)` }}
                    className="w-full absolute top-0 h-6"
                  />
                  <div className="text-xs sm:text-sm font-medium leading-5 text-white2 text-center px-2 sm:px-4">
                    By owning a Shakai, you get perks only available to those
                    part of The Shakai universe. The team at Shakai will be
                    hosting events for all holders. These events range from
                    Yacht parties to maybe even a private island to ourselves.
                    Whatever you guys want done, the team will deliver.
                  </div>
                  <div
                    style={{ backgroundImage: `url(images/roadmapLine.png)` }}
                    className="w-full absolute bottom-0 h-6"
                  />
                </div>
                <div className="flex justify-center items-center mb-4 xl:mb-0 xl:border-b-4 border-yellow2 text-3xl text-white2 f-f-h font-bold w-full">
                  50%
                </div>
              </div>
            </div>
            <img
              src="images/hollowBorder2.png"
              className="-mt-2 xl:block hidden"
              alt="border"
            />

            <div className="lg:w-1/2  h-252px hidden   lg:flex flex-col items-end justify-center mb-8 lg:mb-0">
              <img src="images/5c.png" className="opacity-0" alt="word" />
            </div>
          </div>
          <div className="lg:flex-row flex-col-reverse flex flex-col  w-full items-center mt-8 lg:mt-0">
            <div className="lg:w-1/2 hidden lg:flex opacity-0">
              <img src="images/5c.png" alt="word" />
            </div>
            <img
              src="images/hollowBorder3.png"
              className="-mt-2 xl:block hidden"
              alt="border"
            />
            <div className="lg:w-1/2 xl:-mt-2">
              <div className="flex flex-col items-center justify-center   xl:flex-row xl:items-end w-full">
                <div className="flex justify-center items-center mb-4 xl:mb-0 xl:border-b-4 border-yellow2 text-3xl text-white2 f-f-h font-bold w-full">
                  100%
                </div>
                <div
                  className="flex-shrink-0 w-350px sm:w-414px h-248px border-4 border-yellow2 flex flex-col items-center justify-center relative "
                  style={{ background: `url(images/roadmapBg.png)` }}
                >
                  <div
                    style={{ backgroundImage: `url(images/roadmapLine.png)` }}
                    className="w-full absolute top-0 h-6"
                  />
                  <div className="text-xs sm:text-sm font-medium leading-5 text-white2 text-center px-2 sm:px-4">
                    The team at Shakai plan to release an anime series. A lot of
                    the budget will go into making this look amazing. With the
                    community we build and the connections that come with such a
                    big community we plan to connect with anime studios and get
                    our own made. Each piece of art will have a story attached
                    to it, making your art much more personal.
                  </div>
                  <div
                    style={{ backgroundImage: `url(images/roadmapLine.png)` }}
                    className="w-full absolute bottom-0 h-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
