import React from "react";
import Hero from "../../components/mintPage/hero";
function Index() {
  return (
    <div className="mx-auto container sm:border-r-4 sm:border-l-4 sm:border-black2">
      <Hero />
    </div>
  );
}

export default Index;
