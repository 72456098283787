import React, { useState } from "react";
import Slide from "react-reveal/Slide";
import { Link, useLocation } from "react-router-dom";
function Index() {
  const [menu, setmenu] = useState(false);
  const location = useLocation();
  return (
    <>
      <div id="navbar" className="mx-auto container w-full">
        <ul className="flex  justify-between w-full border-black2 border-b-4">
          <button className="border-l-4 border-black2 border-r-4 lg:hidden">
            <div
              onClick={() => setmenu(true)}
              className="focus:outline-none text-white2  p-5 font-bold f-f-h cursor-pointer  "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=" icon icon-tabler icon-tabler-menu-2"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1={4} y1={6} x2={20} y2={6} />
                <line x1={4} y1={12} x2={20} y2={12} />
                <line x1={4} y1={18} x2={20} y2={18} />
              </svg>
            </div>
          </button>
          <li onClick={()=>window.location.href = "/"} className="cursor-pointer lg:py-30px  p-5 lg:px-70px lg:border-l-4 border-black2 lg:border-r-4">
            <img src="images/logoH.png" alt="logo header"/>
          </li>

          <li className="pt-30px pb-5 lg:flex hidden  flex-col items-center justify-center">
            <ul className="flex items-center space-x-6 w-full f-f-h">
              <a href="/#home">
                <li className="w-14 text-white2 flex flex-col items-center  group cursor-pointer custom-bold relative">
                  <p className="leading-5">Home</p>
                  <div className="absolute mt-6 rounded-full opacity-0  group-hover:opacity-100 transition-all duration-300 ease-in-out bg-yellow2 w-2 h-2" />
                </li>
              </a>
              <a href="/#tagline">
                <li className="w-16 text-white2 flex flex-col items-center  group cursor-pointer custom-bold">
                  <p className="leading-5">Tagline</p>
                  <div className="absolute mt-6 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out bg-yellow2 w-2 h-2" />
                </li>
              </a>
              <a href="/#faq">
                <li className="w-50px text-white2 flex flex-col items-center  group cursor-pointer custom-bold">
                  <p className="leading-5">F.A.Q</p>
                  <div className="absolute mt-6 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out bg-yellow2 w-2 h-2" />
                </li>
              </a>
              <a href="/#roadmap">
                <li className="w-90px text-white2 flex flex-col items-center  group cursor-pointer custom-bold">
                  <p className="leading-5">Roadmap</p>
                  <div className="absolute mt-6 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out bg-yellow2 w-2 h-2" />
                </li>
              </a>
            </ul>
          </li>

          {location.pathname === "/mint" ? (
            <li className="bg-yellow2 lg:py-30px px-5 py-5 lg:px-10  lg:text-xl font-bold text-sm f-f-h cursor-pointer flex items-center  border-l-4 border-black2 border-r-4 leading-10 text-white2">
              <p>Connect Wallet</p>
            </li>
          ) : (
            <li
              onClick={() => (window.location.href = "mint")}
              className="lg:py-30px px-5 py-5 lg:px-10 font-bold lg:text-base text-sm f-f-h cursor-pointer flex items-center  border-l-4 border-black2 border-r-4 leading-10 text-white2"
            >
              Mint Here
              <svg
                className="ml-2 right-bounce"
                width={25}
                height={8}
                viewBox="0 0 25 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.3536 4.35355C24.5488 4.15829 24.5488 3.84171 24.3536 3.64645L21.1716 0.464466C20.9763 0.269204 20.6597 0.269204 20.4645 0.464466C20.2692 0.659728 20.2692 0.976311 20.4645 1.17157L23.2929 4L20.4645 6.82843C20.2692 7.02369 20.2692 7.34027 20.4645 7.53553C20.6597 7.7308 20.9763 7.7308 21.1716 7.53553L24.3536 4.35355ZM0 4.5H24V3.5H0V4.5Z"
                  fill="#FAFAF9"
                />
              </svg>
            </li>
          )}
        </ul>
      </div>
      <Slide left>
        <div
          className={
            menu
              ? "z-50 w-1/2 sm:w-1/3 sm:overflow-y-auto sm:h-full bg-black3  rounded-tr-2xl fixed min-h-screen -ml-2 sm:-ml-4 md:-ml-2 top-0 left-0 shadow-2xl  lg:hidden"
              : "hidden"
          }
        >
          <div className="flex justify-between w-full p-8">
            <Link to="/">
              <p className="text-sm text-white2">Shakai</p>
            </Link>
            <button
              className="focus:outline-none text-white"
              onClick={() => setmenu(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M15 1L1 15"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1L15 15"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <ul className="flex flex-col text-xs pl-8 space-y-6 uppercase my-4 md:mt-8">
            <li className="text-white transition duration-150 ease-in ">
              <a href="/#home">Home</a>
            </li>
            <li className="text-white">
              <a href="/#tagline">Tagline</a>
            </li>
            <li className="text-white">
              <a href="/#faq">F.A.Q</a>
            </li>
            <li className="text-white">
              <a href="/#roadmap">Roadmap</a>
            </li>
          </ul>
        </div>
      </Slide>
    </>
  );
}

export default Index;
