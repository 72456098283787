import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./components/layout";
import Home from "./pages/home";
import Mint from "./pages/mint";
function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/mint" component={Mint} exact />
          </Switch>
        </Layout>
      </Router>
      <a href="#navbar">
        <button
          className={`fixed rounded-full  z-40 right-0 bottom-0 m-4 lg:m-10 xl:m-24 p-2 lg:p-4 bg-black2 shadow-2xl focus:outline-none`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-arrow-narrow-up animate-bounce"
            width={32}
            height={32}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="white"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1={12} y1={5} x2={12} y2={19} />
            <line x1={16} y1={9} x2={12} y2={5} />
            <line x1={8} y1={9} x2={12} y2={5} />
          </svg>
        </button>
      </a>
    </div>
  );
}

export default App;
