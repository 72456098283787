import React, { useState } from "react";
import Heading from "../../common/heading";
import Fade from "react-reveal";

function Index() {
  const [ans, setAns] = useState(null);
  const faq = [
    {
      q: `What is the total supply?`,
      ans: `10,000 members will be able to join The Shakai Universe. These will be a collectors piece, bridging the gap between anime lovers and NFTs.`,
    },
    {
      q: `When is the Release Date? `,
      ans: `Those brave enough to be apart of The Shakai Universe will be able to do so on the 15th of October. Make sure you’re prepared for the drop my fellow weebs!`,
    },
    {
      q: `How many can I purchase?`,
      ans: `The maximum mint limit per person will be 10 Shakai characters. Just purchasing one will ensure you are one of the Shakai otakus.`,
    },
    {
      q: `Is there different types of rarity?`,
      ans: `The class system is one that has been embedded into The Shakai culture. Rarity is one of the most important features in this universe. The collection will range from common to super rare.`,
    },
    {
      q: ` What is the utility of the project? `,
      ans: `We plan to capture that via a biweekly manga series revolving around the characters of the Shakai universe, these will be released online with a limited amount of paperback editions being created and given away to Shakai holders once all chapters have been released. `,
    },
    {
      q: `What is the mint price?`,
      ans: `The mint price to secure your place in the Shakai is 0.10 ETH.`,
    },
  ];
  return (
    <Fade bottom>
      <div id="faq" className="mt-16 md:mt-20 lg:mt-24 pb-16 md:pb-20 lg:pb-24 flex flex-col items-center justify-center px-4 xl:px-0">
        <div className="flex flex-col items-center justify-center w-full md:w-11/12 xl:w-10/12">
          <Heading content="What to Know" />
          <div className="lg:flex-row flex flex-col items-center justify-center w-full mt-8 md:mt-12 lg:mt-16">
            <div className="lg:w-5/12 relative flex ">
              
              <img
                className="flex-shrink-0 z-0 absolute sm:-left-8 sm:block hidden  xl:-left-20"
                src="images/3c.png"
                alt="word"
              />
              <img
                className="w-full flex-shrink-0 z-0"
                src="images/faqside.png"
                alt="team 4"
              />
            </div>
            <div className="lg:pl-100px lg:w-7/12 mt-10 lg:mt-0 w-full flex flex-col items-center lg:items-start">
              <Heading content="F.A.Q" />
              <div className="mt-6 w-full">
                {faq.map((item, i) => {
                  return (
                    <div id={i} className="w-full pt-6 cursor-pointer">
                      <div
                        onClick={() => (ans === i ? setAns(null) : setAns(i))}
                        className={`text-xl font-medium leading-5 text-white2 pb-6 ${
                          ans !== i && "border-b-2 border-black2"
                        }`}
                      >
                        {item.q}
                      </div>

                      <div
                        className={`${
                          ans === i ? "max-h-ease" : "max-h-none hidden"
                        } w-full md:pl-5 ml-3 md:border-l-2 border-black2`}
                      >
                        <div
                          className={`${
                            ans !== i && "mt-6"
                          }  pb-1  font-light text-gray1 leading-6`}
                        >
                          {item.ans}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default Index;
