import React from "react";
import Button from "../../common/button";
import Fade from "react-reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  EffectCoverflow,
  Autoplay,
  Pagination,
} from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
SwiperCore.use([Navigation, EffectCoverflow, Autoplay, Pagination]);
function Index() {
  const images = [
    {
      bg: "#7DF3FF",
      alt: "Anime girl.png",
      img: "images/Anime girl.png",
    },
    {
      bg: "#F05F4F",
      alt: "Army.png",
      img: "images/Army.png",
    },
    {
      bg: "#0EDCFC",
      alt: "Beaten up.png",
      img: "images/Beaten up.png",
    },
    {
      bg: "#71FFBC",
      alt: "Cyborg 2.0.png",
      img: "images/Cyborg 2.0.png",
    },
    {
      bg: "#FF9681",
      alt: "Enlighten .png",
      img: "images/Enlighten.png",
    },
    {
      bg: "#FEA524",
      alt: "Golden boy.png",
      img: "images/Golden boy.png",
    },
    {
      bg: "#88758C",
      alt: "Goth .png",
      img: "images/Goth.png",
    },
    {
      bg: "#F2A13B",
      alt: "Mummy.png",
      img: "images/Mummy.png",
    },
    {
      bg: "#FAD20B",
      alt: "Zombie.png",
      img: "images/Zombie.png",
    },
    {
      bg: "#FF756A",
      alt: "Psycho .png",
      img: "images/Psycho.png",
    },
  ];
  return (
    <>
      <div
        style={{ backgroundImage: `url(images/bg2.png)` }}
        className="md:pb-20 px-4 xl:px-0"
      >
        <Fade bottom>
          <div
            id="home"
            className="flex flex-col items-center justify-center w-full"
          >
            <div className="flex items-start justify-between w-full  relative">
              <img
                className="flex-shrink-0 absolute lg:block hidden left-16 top-0"
                src="images/1.png"
                alt="lantern1"
              />
              <div className="flex items-center justify-center w-full pt-24">
                <div className="lg:w-8/12 flex items-center justify-center">
                  <div className="flex flex-col items-center justify-center w-full">
                    <h1 className="f-f-h text-5xl  xl:leading-80px xl:text-80px font-bold text-white2">
                      The Shakai
                    </h1>
                    <p className="text-white2 text-xl leading-8 mt-5 mb-10 text-center">
                      The Shakkai has bided its time in the shadows, that time
                      is over. The Shakai Society will finally claim its place
                      in the world.
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:flex items-start space-x-2.5 absolute hidden right-5 top-0">
                <img
                  className="flex-shrink-0"
                  src="images/2.png"
                  alt="lantern1"
                />
                <img
                  className="flex-shrink-0"
                  src="images/3.png"
                  alt="lantern1"
                />
              </div>
            </div>
            <div className="sm:flex-row flex flex-col  items-center sm:space-x-5 md:space-x-10 justify-center w-full">
              <Button onClickHandler={""} title="Join Community" />
              <div className="mt-7 sm:mt-0 w-auto">
                <Button onClickHandler={""} title="Explore" />
              </div>
            </div>
          </div>
        </Fade>
      </div>

      <div
        id="team"
        className="flex items-center justify-center w-full mt-16 px-4 xl:px-0"
      >
        <div className="flex items-center justify-center w-full">
          <div className="xl:w-8/12 md:flex-row flex-col flex items-center justify-center relative">
            <img
              src="images/1c.png"
              alt="word"
              className="absolute xl:block hidden -left-32 2xl:-left-20 top-8 z-20"
            />
            <img
              src="images/c2.png"
              alt="cloud 2"
              className="absolute xl:block hidden w-1/4 lg:w-2/5 xl:w-auto lg:-left-10 xl:-left-44 2xl:-left-28 lg:-bottom-8  xl:bottom-20 2xl:bottom-24 z-20"
            />

            <div className="w-full xl:flex  items-center  hidden">
              <img
                src="images/prev.svg"
                alt="arrowback"
                className="swiper-button-prev w-20 h-20 flex-shrink-0 focus:outline-none "
              />
              <Swiper
                effect={"coverflow"}
                keyboard={true}
                navigation={
                  {
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                  }
                }
                pagination={{
                  dynamicBullets: true,
                }}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 138.45,
                  depth: 50,
                  modifier: 5,
                }}
                slidesPerView="auto"
                centeredSlides={true}
                loop={true}
                className="swiper"
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                speed={1200}
              >
                {images.map((item, i) => {
                  return (
                    <SwiperSlide className="slider">
                      <img
                        style={{ background: `${item.bg}` }}
                        src={item.img}
                        alt={item.alt}
                        className="z-0"
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <img
                src="images/next.svg"
                alt="arrowNext"
                className="swiper-button-next w-14 h-14 flex-shrink-0 focus:outline-none "
              />
            </div>

            <CarouselProvider
              className="p-5 bg-transparent  flex items-center xl:hidden relative space-x-4 sm:space-x-10"
              naturalSlideWidth={100}
              naturalSlideHeight={50}
              totalSlides={10}
              infinite={true}
              playDirection={"forward"}
              isPlaying={true}
            >
              <ButtonNext className="focus:outline-none flex-shrink-0">
                <img
                  src="images/prev.svg"
                  alt="arrowback"
                  className="w-8 h-8 sm:w-14 sm:h-14 "
                />
              </ButtonNext>
              <Slider>
                {images.map((item) => {
                  return (
                    <Slide className="h-full w-full">
                      <img
                        style={{ background: `${item.bg}` }}
                        src={item.img}
                        alt={item.alt}
                        className="z-0 border-yellow2 border-4"
                      />
                    </Slide>
                  );
                })}
              </Slider>
              <ButtonBack className="focus:outline-none flex-shrink-0">
                <img
                  src="images/next.svg"
                  alt="arrowback"
                  className="w-8 h-8 sm:w-14 sm:h-14"
                />
              </ButtonBack>
            </CarouselProvider>

            <img
              src="images/c1.png"
              alt="cloud 1"
              className="xl:block hidden absolute -right-10  top-5 2xl:-right-4  2xl:top-8 z-20"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
