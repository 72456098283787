import React from "react";
import Heading from "../../common/heading";
import Fade from "react-reveal";

function Index() {
  return (
    <Fade bottom>
      <div
        id="tagline"
        className="mt-16 md:mt-20 lg:mt-36 flex flex-col items-center justify-center px-4 xl:px-0"
      >
        <div className="flex flex-col items-center justify-center w-full md:w-11/12 xl:w-10/12">
          <Heading content="The Tagline goes here" />
          <div className="w-full relative">
            <div
              style={{ backgroundImage: `url(images/bg.png)` }}
              className=" z-20 mt-6 md:mt-8 lg:mt-12 border-4 border-yellow2 w-full rounded-2xl backdrop-filter backdrop-blur-sm px-5 py-4 md:px-10 md:py-8 lg:px-16 lg:py-10  xl:px-109px xl:py-16 text-center  xl:leading-30px  md:text-lg xl:text-xl text-gray1 relative"
            >
              <img
                alt="left Top"
                src="images/tg1.png"
                className="absolute w-14 md:block hidden xl:w-auto z-10 top-3 left-3 lg:top-6 lg:left-6"
              />
              <img
                alt="right Top"
                src="images/tg2.png"
                className="absolute w-14 md:block hidden xl:w-auto z-10 top-3 right-3 lg:top-6 lg:right-6"
              />
              <img
                alt="left Bottom"
                src="images/tg3.png"
                className="absolute w-14 md:block hidden xl:w-auto z-10 bottom-3 left-3 lg:bottom-6 lg:left-6"
              />
              <img
                alt="Right Bottom"
                src="images/tg4.png"
                className="absolute w-14 md:block hidden xl:w-auto z-10 bottom-3  right-3 lg:bottom-6 lg:right-6"
              />
              The Shakai are unique characters in an expansive universe, as such
              each of their life stories are diverse. We plan to capture that
              via a biweekly manga series revolving around the characters of the
              Shakai universe, these will be released online with a limited
              amount of paperback editions being created and given away to
              Shakkai holders once all chapters have been released. Prepare
              yourself for action, adventure, waifus, comedy and an exceptional
              plot, combined with artwork that will make you crave every new
              issue. We also want to include our community, once all chapters
              have been released we will be holding a community vote where
              members will decide on which chapter they would like to see
              animated!
            </div>
            <img
              src="images/2c.png"
              className="absolute z-0 right-0   -bottom-14 sm:-bottom-24 md:-bottom-28 lg:-bottom-32  w-1/4 lg:w-auto xl:-right-24"
              alt="word"
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default Index;
