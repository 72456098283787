import React from "react";
import Hero from "../../components/homePage/hero";
import Tagline from "../../components/homePage/tagline";
import FAQ from "../../components/homePage/faq";
import Roadmap from "../../components/homePage/roadmap";
function Index() {
  return (
    <div className="mx-auto container sm:border-r-4 sm:border-l-4 sm:border-black2">
      <Hero />
      <Tagline />
      <FAQ />
      <Roadmap />
    </div>
  );
}

export default Index;
