import React, { useState } from "react";
import Button from "../common/button";
import Heading from "../common/heading";
import Fade from "react-reveal";

function Hero() {
  const max = 10;
  const [value, setValue] = useState(1);
  return (
    <>
      <div
        style={{ backgroundImage: `url(images/bg2.png)` }}
        className="md:pb-20 px-4 xl:px-0"
      >
        <Fade bottom>
          <div className="flex flex-col items-center justify-center w-full">
            <div className="flex items-start justify-between w-full  relative">
              <img
                className="flex-shrink-0 absolute lg:block hidden left-16 top-0"
                src="images/1.png"
                alt="lantern1"
              />
              <div className="w-full flex items-center justify-center pt-24">
                <Heading content="" /> 
                {/* presale now live */}
              </div>
              <div className="lg:flex items-start space-x-2.5 absolute hidden right-5 top-0">
                <img
                  className="flex-shrink-0"
                  src="images/2.png"
                  alt="lantern1"
                />
                <img
                  className="flex-shrink-0"
                  src="images/3.png"
                  alt="lantern1"
                />
              </div>
            </div>
          </div>
        </Fade>
        {/* subtitute div */}
        <div className="h-80 md:h-40 lg:h-56"/>
      </div>
      <Fade bottom>
        <div className="flex items-center justify-center w-full -mt-60 md:-mt-32 px-4 xl:px-0">
          <div className="flex items-center justify-center w-full">
            <div className="xl:w-9/12 lg:flex-row flex-col flex items-center lg:items-start justify-center lg:justify-between relative lg:space-x-14 xl:space-x-32">
              <div className="w-350px sm:w-414px flex flex-col items-start flex-shrink-0">
                <div className="border-10px border-yellow2 rounded-20px h-414px w-full">
                  <img
                    className="w-full h-full rounded-md"
                    alt="gif"
                    src="images/20 shakai gif.gif"
                  />
                </div>
                <img alt="word" src="images/mint.png" className="mt-3.5" />
              </div>
              <div className="flex flex-col items-end justify-center w-full mt-8 lg:mt-0">
                <div className="flex flex-col items-center justify-center w-full">
                  <p className="text-white">
                    Make sure to connect your Wallet before purchasing
                  </p>
                  <div className="mt-12 w-full">
                    <div className="flex items-center justify-between w-full pb-8 border-b-4 border-black2 text-white">
                      <p className="text-2xl f-f-h font-bold leading-6">
                        ETH Balance
                      </p>
                      <p className="text-2xl f-f-h font-bold leading-6">
                        0 ETH
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full py-5 border-b-4 border-black2 text-white">
                      <div className="flex items-center space-x-3">
                        <p className="text-2xl f-f-h font-bold leading-6">
                          Amount
                        </p>
                        <button
                          onClick={() => setValue(max)}
                          className="w-14 bg-black4 flex flex-col items-center justify-center h-5 rounded-5px text-xs text-white font-bold"
                        >
                          Max
                        </button>
                      </div>
                      <div className="flex items-center space-x-3">
                        <input
                          type="number"
                          placeholder={value}
                          value={value}
                          readOnly
                          min="0"
                          className="w-36 text-right focus:outline-none text-2xl bg-transparent f-f-h placeholder-white font-bold text-white"
                        />
                        <div className="flex flex-col items-center justify-center space-y-3">
                          <button
                            onClick={() => {
                              value === max
                                ? setValue(max)
                                : setValue(value + 1);
                            }}
                            className="focus:outline-none"
                          >
                            <svg
                              width={12}
                              height={8}
                              viewBox="0 0 12 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.99989 -2.40193e-06L11.6572 5.65733L9.77056 7.54266L5.99989 3.77066L2.22923 7.54266L0.342559 5.65733L5.99989 -2.40193e-06Z"
                                fill="#EBBC63"
                              />
                            </svg>
                          </button>
                          <hr className="border-b border-yellow2 w-4" />
                          <button
                            onClick={() => {
                              value >= 1 && setValue(value - 1);
                            }}
                            className="focus:outline-none"
                          >
                            <svg
                              width={12}
                              height={8}
                              viewBox="0 0 12 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.00011 8L0.342773 2.34267L2.22944 0.457336L6.00011 4.22934L9.77077 0.457336L11.6574 2.34267L6.00011 8Z"
                                fill="#EBBC63"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full py-8 border-b-4 border-black2 text-white">
                      <p className="text-2xl f-f-h font-bold leading-6">
                        Total Amount
                      </p>
                      <p className="text-2xl f-f-h font-bold leading-6">
                        0 ETH
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full py-8 border-b-4 border-black2 text-white">
                      <p className="text-2xl f-f-h font-bold leading-6">
                        My Tickets
                      </p>
                      <p className="text-2xl f-f-h font-bold leading-6">
                        0
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sm:flex-row flex flex-col  items-center sm:space-x-5 md:space-x-10 mt-12 justify-center 2xl:pr-8">
                  <Button
                    onClickHandler={""}
                    title="Buy Tickets"
                    disabled={true}
                  />
                  <div className="mt-7 sm:mt-0 w-auto">
                    <Button
                      onClickHandler={""}
                      title="Claim Shakai"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
}

export default Hero;
