import React from "react";

function Button({ title, onClickHandler = null, disabled = false }) {
  return (
    <button
      disabled={disabled}
      style={{
        backgroundImage: `url(${
          disabled ? "images/btn2.png" : "images/btn.png"
        })`,
      }}
      onClick={(e) => {
        onClickHandler && onClickHandler();
      }}
      className={`w-197px h-51px flex items-center justify-center flex-col focus-within:outline-none transition-all duration-500 ease-in-out  ${
        disabled ? "text-gray1 hover:shadow-btn2" : "text-black3 hover:shadow-btn"
      } f-f-h relative`}
    >
      <svg
        className="absolute top-2 left-2 flex-shrink-0"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="2.83793"
          y1="2.1119"
          x2="19.8085"
          y2="19.0825"
          stroke="currentColor"
        />
        <line
          x1="8.13163"
          y1="2.47488"
          x2="19.4453"
          y2="13.7886"
          stroke="currentColor"
        />
        <line
          x1="3.18192"
          y1="7.42461"
          x2="14.4956"
          y2="18.7383"
          stroke="currentColor"
        />
      </svg>
      {title}

      <svg
        className="absolute bottom-2 right-2 flex-shrink-0"
        width={23}
        height={23}
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="3.83768"
          y1="3.11178"
          x2="20.8628"
          y2="20.1369"
          stroke="currentColor"
        />
        <line
          x1="9.16557"
          y1="3.45882"
          x2="20.5157"
          y2="14.8089"
          stroke="currentColor"
        />
        <line
          x1="4.16581"
          y1="8.45882"
          x2="15.5159"
          y2="19.8089"
          stroke="currentColor"
        />
      </svg>
    </button>
  );
}

export default Button;
